import React, { useEffect, useState } from 'react';

import Init from '../../../../Init/Init';
import './OrderInstruction.scss';

import LoadingPage from '../../../Utilities/LoadingPage/LoadingPage';
import PrimaryButton from '../../../Utilities/PrimaryButton/PrimaryButton';

import useAnalyticsEventTracker from '../../../../GoogleAnalytics/useAnalyticsEventTracker';
import instructionAPI from '../../../../Data/OrderInstruction';

const GaTracker = ({
    action,
    label
}) => {
    const gaEventCategory = 'Order Instruction';

    const gaEventTracker = useAnalyticsEventTracker(gaEventCategory);

    gaEventTracker(action, label);
};

const OrderInstruction = (props) => {
    const packageIcon = Init.config.icons.package;
    const cupImage = Init.config.orderInstruction.cup;
    const illustration = Init.config.orderInstruction.illustration;
    const warningIcon = Init.config.icons.warning;

    const [orderInstruction, setOrderInstruction] = useState([]);

    // Call API
    useEffect(() => {
        ( async () => {
            const response = await instructionAPI.instructionList();
            console.log("Render instructions!");

            if( response.hasOwnProperty('errorCode') && response.errorCode === 0 ) {
                setOrderInstruction(response.data);
            } else {
                setOrderInstruction([]);
            }
        } )();
    }, []);

    const order = () => {
        window.scrollTo({
            top: document.getElementById("packaging-catalog").offsetTop,
            behavior: 'smooth'
        });
    }

    return (
        <div className="order-instruction">
            {/* 
                1. Header (Title, Desc, Icon)
                2. Catalog
            */}
            <div className="order-instruction-header">
                <div className="order-instruction-header-content">
                    <h2 className="order-instruction-title">
                        Cara Order
                    </h2>
                    <p className="order-instruction-desc">
                        *note: untuk pemesanan custom 1 warna, order min. 250 pcs.
                    </p>
                </div>
                <div className="order-instruction-header-icon">
                    <img src={ packageIcon } alt="" />
                </div>
            </div>

            <div 
                className="order-instruction-content"
            >
                {/* 
                    1. Image
                    2. Instructions
                */}
                <div 
                    style={{
                        backgroundImage: `url(${illustration})`,
                        backgroundSize: 'contain',
                    }}
                    className="order-instruction-content-image">
                    <img src={ cupImage } alt="cup-image" />
                </div>
                <div className="order-instruction-content-instruction">
                    <div className="instruction-list">
                        {
                            orderInstruction.map( (item, index) => {
                                return (
                                    <div key={index} className="instruction-item">
                                        <span className="instruction-item-index"> { index + 1 } </span>
                                        <span className="instruction-item-text"> { item } </span>
                                    </div>
                                )
                            } )
                        }
                    </div>
                    <div className="instruction-warning">
                        <img src={ warningIcon } alt="" />
                        <p className='instruction-warning-text'>
                            Warna hasil cetak di produk memiliki akurasi kesamaan 85% dengan warna desain di file kamu. Jadi tidak akan 100% sama ya.
                        </p>
                    </div>
                </div>
            </div>
            <div className="order-instruction-btn-container">
                <PrimaryButton 
                    onClick={ () => order() }
                    customClass={ `order-instruction-btn` }
                    size={"sm"}
                > Pesan Sekarang </PrimaryButton>
            </div>
            <div className="order-instruction-footer">
                <p>
                    Kami mengerti bahwa Branding adalah Investasi. Kemasan dengan kualitas tinggi akan menjadi cerminan dan identitas sebuah brand.
                </p>
                <p>
                    Fokus kami menyediakan kemasan "HIGH QUALITY" untuk para pelaku industri Food & Beverages di Indonesia.
                </p>
            </div>
        </div>
    );
}



export default OrderInstruction;