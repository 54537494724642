import React, { 
    useState, 
    useEffect, 
    useRef
    // useContext 
} from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Accordion, Offcanvas } from 'react-bootstrap';

import PrimaryButton from '../../Utilities/PrimaryButton/PrimaryButton';
import SecondaryButton from '../../Utilities/SecondaryButton/SecondaryButton';

import Init from '../../../Init/Init';
import UtilityFunction from '../../../Util/Util';
import './MobileSidebar.scss';

import { connect } from 'react-redux';
import { userLogout } from '../../../Redux/Auth/auth-action';

import productAPI from '../../../Data/Product';

const { objectIsEmpty, buildWaLink } = UtilityFunction;

const mapStateToProps = (state) => {
    return {
        identity: state.auth.identity,
        cartCounter: state.shop.cartCounter
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        logout: () => dispatch(userLogout())
    }
}

const MobileSidebar = (props) => {
    const [ show, setShow ] = useState(false);
    const [ data, setData ] = useState([]);
    const [ isLogin, setIsLogin ] = useState(false);
    const [ identity, setIdentity ] = useState({});
    const [ cartCounter, setCartCounter ] = useState(0);
    const waLink = buildWaLink();

    const [ menu, setMenu ] = useState([]);

    const searchText = useRef('');

    const { FontAwesomeIcon, config } = Init;
    const { allowTransaction } = config;

    const openLoginPage = () => {
        navigate('/login');
        handleClose();
    };

    const openCartPage = () => {
        navigate('/cart');
    }

    const logout = () => {
        props.logout();
    }

    const navigate = useNavigate();

    // Offcanvas
    const handleClose = () => {
        props.onClose();
    };

    const searchProduct = (event) => {
        const searchKeyword = searchText.current.value;
        
        let searchPath = `/category?s=${searchKeyword}`;
        
        navigate(`${searchPath}`, {
            replace: false
        });
        handleClose();
    }

    useEffect( () => {
        setShow(props.show);
    }, [ props.show ] );

    useEffect( () => {
        setIdentity(props.identity);
    }, [ props.identity ] );

    useEffect( () => {
        setMenu(props.menu);
    }, [ props.menu ]);

    useEffect( () => {
        setIsLogin(!objectIsEmpty(identity));
    }, [ identity ]);

    useEffect( () => {
        setCartCounter(props.cartCounter);
    }, [ props.cartCounter ]);

    return (
        <>
            <Offcanvas className="mobile-sidebar-container" placement='end' show={show} onHide={handleClose}>
                <Offcanvas.Header className='mobile-sidebar-header' closeButton>
                    <Offcanvas.Title className="mobile-sidebar-title">
                        Menu
                    </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body className='mobile-sidebar-body'>
                    {/* 
                        3 big parts!
                        1. Auth:
                        IF LOGIN:
                            Display icon with username
                            Display Cart & Logout Button
                        ELSE:
                            Display Cart & Login Button
                        2. Search Bar
                        3. Categories List
                    */}
                    {
                        allowTransaction && (
                            <>
                                {
                                    isLogin ? (
                                        <>
                                            <div className="body-section auth-section">
                                                <div className="auth-username">
                                                    <FontAwesomeIcon className='auth-icon' icon={['fas', 'user']} /> Hello, <b>{ identity.customer_username }</b>
                                                </div>
                                                <PrimaryButton size='sm' customClass="mobile-login-btn" onClick={() => logout()}>
                                                    <FontAwesomeIcon className='category-item-icon' icon={['fas', 'sign-out-alt']} /> Logout
                                                </PrimaryButton>
                                            </div>
                                        </>
                                    ) : (
                                        <div className="body-section auth-section">
                                            <PrimaryButton size='sm' customClass="mobile-login-btn" onClick={() => openLoginPage()}>
                                                <FontAwesomeIcon className='category-item-icon' icon={['fas', 'sign-in-alt']} /> Login
                                            </PrimaryButton>
                                        </div>
                                    )

                                }
                                <div className="body-section sidebar-section">
                                    <Link to={"/cart"} className="sidebar-item cart" onClick={() => handleClose()}>
                                        <FontAwesomeIcon className='sidebar-item-icon' icon={['fas', 'shopping-cart']}  />
                                        {
                                            cartCounter > 0 ? (
                                                <span className="cart-counter">
                                                    { cartCounter }
                                                </span>
                                            ) : (
                                                ''
                                            )
                                        } Shopping Cart
                                    </Link>
                                </div>
                            </>
                        ) 
                    }
                    <Accordion className="body-section sidebar-section">
                        {
                            menu.map( (item, index) => {
                                const navPath = `/category/`;
                                let searchQuery = '';

                                switch(item.group_type) {
                                    case productAPI.enum.fetchType.brand:
                                        searchQuery = `?b={id}`;
                                        break;
                                    case productAPI.enum.fetchType.category:
                                        searchQuery = `?p={id}`;
                                        break;
                                    case productAPI.enum.fetchType.search:
                                        searchQuery = `?s={name}`;
                                        break;
                                    default:
                                        break;
                                }

                                return item.display_subgroup ? (
                                    <Accordion.Item eventKey={index} key={index}>
                                        <Accordion.Header className="category-item">
                                            { item.group_name }
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            {
                                                item.group_product.map( (v, k) => {
                                                    return (
                                                        <Link key={k}
                                                            className={`sidebar-item`}
                                                            to={`${navPath}${searchQuery.replace('{id}', v.id).replace('{name}', v.name)}`}
                                                            onClick={ () => handleClose() }
                                                        >
                                                            { v.name }
                                                        </Link>
                                                    );
                                                })
                                            }
                                        </Accordion.Body>
                                    </Accordion.Item>
                                ) : (
                                    <Link key={index} to={ `${navPath}${item.id}` } className="sidebar-item" onClick={() => handleClose()}>
                                        { item.group_name }
                                    </Link>
                                )
                            } )
                        }
                    </Accordion>
                    <div className="body-section sidebar-section">
                        {
                            isLogin ? (
                                <>
                                    <Link to={"/profile/account"} className="sidebar-item" onClick={() => handleClose()}>
                                        <FontAwesomeIcon className='sidebar-item-icon' icon={['fas', 'user']} /> Detail Akun
                                    </Link>
                                    <Link to={"/profile/address"} className="sidebar-item" onClick={() => handleClose()}>
                                        <FontAwesomeIcon className='sidebar-item-icon' icon={['fas', 'map-marker-alt']} /> Alamat Saya
                                    </Link>
                                    <Link to={"/profile/order"} className="sidebar-item" onClick={() => handleClose()}>
                                        <FontAwesomeIcon className='sidebar-item-icon' icon={['fas', 'box']} /> Pesanan Saya
                                    </Link>
                                </>
                            ) : ''
                        }
                        <Link className='sidebar-item' to="/payment-confirmation" onClick={() => handleClose()}>
                            <FontAwesomeIcon className='sidebar-item-icon' icon={['fas', 'check-circle']} /> Konfirmasi Pembayaran
                        </Link>
                        <a className='sidebar-item' href={ waLink }>
                            <FontAwesomeIcon className='sidebar-item-icon' icon={['fas', 'phone']} /> Hubungi Kami
                        </a>
                        <Link className='sidebar-item' to="/terms-and-condition" onClick={() => handleClose()}>
                            <FontAwesomeIcon className='sidebar-item-icon' icon={['fas', 'question-circle']} /> Terms & Conditions
                        </Link>
                        <Link className='sidebar-item' to="/privacy-policy" onClick={() => handleClose()}>
                            <FontAwesomeIcon className='sidebar-item-icon' icon={['fas', 'exclamation-circle']} /> Privacy Policy
                        </Link>
                        {/* <Link className='sidebar-item' to="/return-policy" onClick={() => handleClose()}>
                            <FontAwesomeIcon className='sidebar-item-icon' icon={['fas', 'exchange']} /> Return Policy
                        </Link> */}
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(MobileSidebar);