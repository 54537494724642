import Init from '../Init/Init';
import api from './API';

import UtilityFunction from '../Util/Util';

const { responseFormat } = UtilityFunction;

const basePath = 'product';
const pathList = {
    productList: `/`,
    productDetail: `/:param`,
};

const dummy = [
    {
        id: 1,
        productImage: `${ Init.config.productDummyFolder }custom-cup-pp.png`,
        productCode: `CCPP`,
        productName: `Custom Cup PP`,
        product_quantities: [
            
        ]
    },
    {
        id: 2,
        productImage: `${ Init.config.productDummyFolder }custom-sleeve-printing.png`,
        productCode: `CSP`,
        productName: `Custom Sleeve Printing`
    },
    {
        id: 3,
        productImage: `${ Init.config.productDummyFolder }custom-cup-premium-injection.png`,
        productCode: `CCPI`,
        productName: `Custom Cup Premium Injection`
    },
    {
        id: 4,
        productImage: `${ Init.config.productDummyFolder }custom-cup-pet.png`,
        productCode: `CCP`,
        productName: `Custom Cup Pet`
    },
    {
        id: 5,
        productImage: `${ Init.config.productDummyFolder }custom-hot-paper-cup-single-wall.png`,
        productCode: `CHPCSW`,
        productName: `Custom Hot Paper Cup Single Wall`
    },
    {
        id: 6,
        productImage: `${ Init.config.productDummyFolder }custom-hot-paper-cup-double-wall.png`,
        productCode: `CHPCDW`,
        productName: `Custom Hot Paper Cup Double Wall`
    },
    {
        id: 7,
        productImage: `${ Init.config.productDummyFolder }custom-lid-sealer.png`,
        productCode: `CLS`,
        productName: `Custom Lid Sealer`
    },
    {
        id: 8,
        productImage: `${ Init.config.productDummyFolder }custom-paper-cup-ice-cream.png`,
        productCode: `CPCIC`,
        productName: `Custom Paper Cup Ice Cream`
    },
    {
        id: 9,
        productImage: `${ Init.config.productDummyFolder }custom-paper-locking.png`,
        productCode: `CPL`,
        productName: `Custom Paper Locking`
    },
    {
        id: 10,
        productImage: `${ Init.config.productDummyFolder }custom-paper-lunch-box.png`,
        productCode: `CPLB`,
        productName: `Custom Paper Lunch Box`
    },
    {
        id: 11,
        productImage: `${ Init.config.productDummyFolder }custom-paper-bowl.png`,
        productCode: `CPB`,
        productName: `Custom Paper Bowl`
    },
];

const fetchType = {
    brand: 1,
    category: 2,
    search: 3,
    storefront: 4,
    group: 5
}

for(let key in pathList) {
    pathList[key] = basePath + pathList[key];
}

const productList = async () => {
    try {
        const apiPath = pathList.productList;
        const products = await api.getCall(apiPath);

        return products;
    } catch(e) {
        console.log(e.toString());
    
        return responseFormat(-1, "Failed to get products!");
    }
    // return responseFormat(0, "Successfully get products", dummy);
};

const productDetail = async (argParam) => {
    try {
        const apiPath = pathList.productDetail;
        const product = await api.getCall(apiPath, argParam);

        return product;
    } catch(e) {
        console.log(e.toString());
    }
};

const productAPI = {
    enum: {
        fetchType
    },
    dummy: {
        product: dummy,
    },

    productList,
    productDetail,
}

export default productAPI;