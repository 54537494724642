import React, { 
    useState, 
    // useRef, 
    useEffect, 
    useContext, 
    useRef
} from 'react';
import DOMPurify from 'dompurify';

import Init from '../../../../Init/Init';
import UtilityFunction from '../../../../Util/Util';

import './ProductContent.scss';
import CustomToast from '../../../Utilities/Toast/Toast';
import PrimaryButton from '../../../Utilities/PrimaryButton/PrimaryButton';
import SecondaryButton from '../../../Utilities/SecondaryButton/SecondaryButton';

import NavigationContext from '../../../../Context/NavigationContext';

import { connect } from 'react-redux';
import shoppingCartActions from '../../../../Redux/Shopping/shopping-action';

import useAnalyticsEventTracker from '../../../../GoogleAnalytics/useAnalyticsEventTracker';
import CheckoutBox from '../CheckoutBox/CheckoutBox';
import { Row } from 'react-bootstrap';
import FormInput from '../../../Utilities/FormInput/FormInput';

const GaTracker = ({
    action,
    label
}) => {
    const gaEventCategory = 'Product Detail';

    const gaEventTracker = useAnalyticsEventTracker(gaEventCategory);

    gaEventTracker(action, label);
};

const { numberFormatting, buildWaLink } = UtilityFunction;
const { addToCart, saveDesignAttachment } = shoppingCartActions;

const AddonList = (props) => {
    const [selectedAddon, setSelectedAddon] = useState(null);
    const existingAddon = props.addonList;
    // console.log(existingAddon);

    const selectAddon = (addonId) => {
        props.selectAddon(addonId);
    }

    useEffect( () => {
        setSelectedAddon(props.selected);
    }, [ props.selected ]);
    
    return (
        existingAddon &&
        existingAddon.length > 0 ? (
            existingAddon.map( (item, index) => {
                return (
                    <div 
                        key={index} 
                        onClick={() => selectAddon(item.id)} 
                        className={
                            `
                            product-attr-item 
                            ${selectedAddon === item.id ? 'active' : ''}
                            `
                        }>
                        { item.addon_name }
                    </div>
                )
            })
        ) : ''
    );
}

const ProductContent = (props) => {
    /**
     * Initiate variables
     */
    const { FontAwesomeIcon, config } = Init;
    const { addToCart, selectVariant } = props;

    const [ customerCode, setCustomerCode ] = useState(null);
    const [ selectedVariant, setSelectedVariant ] = useState(null);
    const [ selectedAddon, setSelectedAddon ] = useState(0);
    const [ quantity, setQuantity ] = useState(config.orderRule.moq);
    const [ useMultiColor, setUseMultiColor ] = useState(null);

    const designAttachmentRef = useRef(props.attachment);
    
    const { allowTransaction } = config;
    const { productDetail } = props;

    // const app = useContext(AppContext);
    const navigation = useContext(NavigationContext);
    const { navigate } = navigation;
    const sharedLink = `${process.env.REACT_APP_URL}product/${productDetail.product_id}/${productDetail.product_code}`;

    const shareOptions = [
        {
            icon: ['fab', 'facebook'],
            link: ''
        },
        {
            icon: ['fab', 'whatsapp'],
            link: buildWaLink(false, `${sharedLink}\n\n${productDetail.variant_name} - Dapatkan sekarang juga di YoboyCup!`)
        },
        {
            icon: ['fab', 'instagram'],
            link: ''
        }
    ];

    useEffect( () => {
        if(props.identity !== undefined && props.identity.hasOwnProperty('customer_code')) {
            setCustomerCode(props.identity.customer_code);
        }
    }, [props.identity]);

    useEffect( () => {
        // setSelectedVariant(null);
        setQuantity(props.productDetail.product.moq);
    }, [props.productDetail]);

    useEffect( () => {
        setQuantity(config.orderRule.moq);
        if( selectedVariant !== null ) {
            selectVariant(productDetail.variants[selectedVariant].id);
        }
    }, [selectedVariant]);

    useEffect( () => {

    }, [props.addons]);

    const selectSize = (sizeId) => {
        let sizeName = productDetail.variants.hasOwnProperty(sizeId) ? productDetail.variants[sizeId].size_name : '';
        let outOfStock = productDetail.variants.hasOwnProperty(sizeId) && productDetail.variants[sizeId].quantity <= 0;

        GaTracker({
            action: `Choose Size`,
            label: `Product ${productDetail.variant_name}. Chosen Size: ${sizeName}. In stock: ${ outOfStock ? 'No' : 'Yes' }`
        });

        if( !outOfStock ) {
            setSelectedVariant(sizeId);
        }

    }

    const selectAddon = (addonId) => {
        console.log(addonId);
        setSelectedAddon(addonId);
    }

    const changeCounter = (counter) => {
        GaTracker({
            action: `Change Quantity`,
            label: `Product ${productDetail.variant_name}. Updated Qty: ${productDetail.variants[selectedVariant].quantity}`
        });

        setQuantity(counter);
    }

    const addCartItem = () => {
        if(quantity > 0) {
            const cartItem = {
                productId: productDetail.product.id,
                sizeId: parseInt(productDetail.variants[selectedVariant].size_id),
                addons: selectedAddon !== null ? selectedAddon : 0,
                quantity,
                useMultiColor
            };

            const ownerCode = customerCode !== null ? customerCode : 'guest';

            addToCart(ownerCode, cartItem);
            
            // GaTracker({
            //     action: `Add To Cart`,
            //     label: `Add product ${productDetail.variant_name} size ${productDetail.variants[selectedVariant].size_name} ${productDetail.variants[selectedVariant].quantity}x`
            // });

            const photoPath = productDetail.product.product_image !== null ? `${productDetail.product.product_image}` : '';
            const chosenPrice = productDetail.variants[selectedVariant].discount_price > 0 ? productDetail.variants[selectedVariant].discount_price : productDetail.variants[selectedVariant].price;
            
            setToastContent({
                title: 'Added to cart!',
                content: {
                    title: productDetail.product.product_name,
                    variant: `${productDetail.variants[selectedVariant].size_name} ${ selectedAddon ? `+ ${renderAddonName()}` : "" }`,
                    price: numberFormatting((chosenPrice * quantity) + getAddonPrice())
                },
                status: 1,
                show: true,
                image: photoPath
            });
        } else {
            //
        }

    }

    // Add On
    const renderAddonImage = () => {
        for( let index in props.addons ) {
            let item = props.addons[index];

            if( item.id === selectedAddon ) return item.addon_image;
        }

        return "";
    }

    const renderAddonName = () => {
        for( let index in props.addons ) {
            let item = props.addons[index];

            if( item.id === selectedAddon ) return item.addon_name;
        }

        return "";
    }

    const getAddonPrice = () => {
        for( let index in props.addons ) {
            let item = props.addons[index];

            if( item.id === selectedAddon ) return quantity * parseInt(item.price);
        }

        return 0;
    }
    
    const renderAttachment = () => {
        return ``;
    }

    const updateAttachment = (file) => {
        designAttachmentRef.current = file;
        props.saveAttachment(file);
    }

    // Toast
    const [toastContent, setToastContent] = useState({
        title: '',
        content: {
            title: '',
            variant: '',
            price: ''
        },
        status: null,
        show: false
    });

    const closeToast = () => {
        setToastContent({
            title: '',
            content: {
                title: '',
                variant: '',
                price: ''
            },
            status: null,
            show: false
        });
    };

    const navigateToCart = () => {
        navigate('/cart');
    };

    return (
        <>
            <CustomToast show={toastContent.show} 
                    onClose={closeToast} 
                    title={toastContent.title}  
                    status={toastContent.status}
                    customClass='add-to-cart-toast'
            >
                <div className="cart-item-image">
                    <img src={toastContent.image} alt="" />
                </div>
                <div className="cart-item-content">
                    <div className="cart-item-first-row">
                        <div className="title">
                            { toastContent.content.title }
                        </div>
                        <div className="size">
                            { toastContent.content.variant }
                        </div>
                    </div>
                    <div className="cart-item-second-row">
                        <div className="price">
                            { toastContent.content.price }
                        </div>
                    </div>
                </div>
                <div className="cart-item-button-container">
                    <SecondaryButton size="sm" customClass="btn-close-toast" onClick={() => closeToast()}>
                        Tutup
                    </SecondaryButton>
                    <PrimaryButton size="sm" customClass="btn-check-cart" onClick={() => navigateToCart()}>
                        Cek Keranjang <FontAwesomeIcon icon={['fas', 'arrow-right']} />
                    </PrimaryButton>
                </div>
            </CustomToast>

            <div className="product-content">
                {/* 
                    1. Product Name
                    2. Brand / Category
                    3. Price
                    -----------------------------
                    4. Size List
                    -----------------------------
                    5. Buy Quantity
                    -----------------------------
                    6. Button "Add To Cart"
                */}
                <div className="product-content-header">
                    <h1 className='product-name'>
                        { productDetail.product.product_name }
                    </h1>
                    <h3 className='order-term'>
                        *Min. order mulai dari <span className='highlighted'>{ productDetail.product.moq } pcs</span>
                    </h3>
                    {/* <span className="product-category-name">
                        { productDetail.product_category_name } / { productDetail.product_name }
                    </span> */}
                    <div className="product-description" dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(productDetail.product.description)}}>
                    </div>
                    {
                        productDetail.variants.hasOwnProperty(selectedVariant) ? (
                            productDetail.variants[selectedVariant].hasOwnProperty('discount_price') && productDetail.variants[selectedVariant].discount_price > 0 ?
                            (
                                <>
                                    <span className="product-price discounted">
                                        { numberFormatting(productDetail.variants[selectedVariant].price) }
                                    </span>
                                    <span className="product-price">
                                        { numberFormatting(productDetail.variants[selectedVariant].discount_price) }
                                    </span>
                                </>
                            ) : (
                                <span className="product-price">
                                    { numberFormatting(productDetail.variants[selectedVariant].price) }
                                </span>
                            )
                        ) : (
                            // <span className="product-price">
                            //     Choose Size
                            // </span>
                            ""
                        )
                    }
                </div>
                <div className="product-attr-list-container">
                    <div className="product-attr-list">
                        {
                            Object.keys(productDetail.variants).length > 0 ? (
                                Object.keys(productDetail.variants).map( (item, index) => {
                                    let value = productDetail.variants[item];

                                    return (
                                        <div 
                                            key={index} 
                                            onClick={() => selectSize(item)} 
                                            className={
                                                `
                                                product-attr-item 
                                                ${selectedVariant === item ? 'active' : ''} 
                                                ${ value.quantity ? '' : 'disabled' }
                                                `
                                            }>
                                            { value.size_name }
                                        </div>
                                    )
                                } )
                            ) : ''
                        }
                    </div>
                </div>
                {
                    selectedVariant && (props.addons.length > 0 || productDetail.variants[selectedVariant].product_addons.length > 0) ? (
                        <>
                            <div className="product-attr-list-container">
                                <h3 className="product-attr-list-title">
                                    { productDetail.product.is_package ? `Add ons:` : `Add ons for:` }
                                </h3>
                                {
                                    selectedAddon ? (
                                        <img className='product-attr-image' src={renderAddonImage()} alt="" />
                                    ) : ""
                                }
                                <div className="product-attr-list">
                                    <AddonList 
                                        selected={selectedAddon}
                                        addonList={ props.addons.filter( (value) => {
                                            return (productDetail.variants[selectedVariant].product_addons).includes(value.id.toString());
                                        } ) }
                                        // addonList={ props.addons.filter( (value) => {
                                        //     console.log(value);
                                        //     console.log(productDetail.product.product_addons);
                                        //     return productDetail.product.product_addons.includes(value.id.toString());
                                        // } ) }
                                        selectAddon={ (value) => selectAddon(value) }
                                    />
                                </div>
                            </div>
                            <div className="product-attr-list-container">
                                <h3 className="product-attr-list-title">
                                    Upload your design:
                                </h3>
                                {/* {
                                    designAttachmentRef.current ? (
                                        <img className='product-attr-image' src={renderAttachment()} alt="" />
                                    ) : ""
                                } */}
                                <div className="product-attr-list limitless">
                                    <Row className={`custom-design-form`}>
                                        <FormInput  
                                            attribute={{
                                                label: "",
                                                ref: designAttachmentRef,
                                                value: props.attachment,
                                                width: 12,
                                                type: "file",
                                                required: false,
                                                allowedFileTypes: ['PDF', 'PSD'],
                                                maxFileSize: 100 // MB
                                            }}
                                            options={[]}
                                            name={"design_attachment"} 
                                            onChange={ (name, value) => updateAttachment(value) } 
                                            customClass={`custom-design-form-input`}
                                        />
                                    </Row>
                                </div>
                            </div>
                            <div className="product-attr-list-container">
                                <h3 className="product-attr-list-title">
                                    Pilih Jumlah Warna:
                                </h3>
                                <div className="product-attr-list">
                                    <div  
                                        onClick={() => setUseMultiColor(() => {   
                                            return 0;
                                        })} 
                                        className={
                                            `
                                            product-attr-item 
                                            ${useMultiColor === 0 ? 'active' : ''}
                                            `
                                        }>
                                            1 Warna
                                    </div>
                                    <div  
                                        onClick={() => setUseMultiColor(() => {   
                                            return 1;
                                        })} 
                                        className={
                                            `
                                            product-attr-item 
                                            ${useMultiColor === 1 ? 'active' : ''}
                                            `
                                        }>
                                            2 - 4 Warna
                                    </div>
                                </div>
                            </div>
                        </>
                    ) : ''
                }
            </div>
            {
                allowTransaction && selectedVariant && useMultiColor !== null ? (
                    <CheckoutBox 
                        productImage={ productDetail.variants[selectedVariant].variant_image }
                        productName={ productDetail.product.product_name }
                        variantName={ productDetail.variants[selectedVariant].size_name }
                        changeCounter={ (counter) => changeCounter(counter) }
                        moq={ productDetail.product.moq }
                        directToWa={ productDetail.product.direct_to_wa }
                        selectedVariant={ selectedVariant }
                        quantity={ quantity }
                        totalPrice={ (quantity * productDetail.variants[selectedVariant].discount_price) + getAddonPrice() }
                        pricePerUnit={ productDetail.variants[selectedVariant].discount_price }
                        addToCartDisabled={ !selectedVariant || !quantity }
                        addToCart={ () => addCartItem() }
                        addon={ renderAddonName() }
                        useMultiColor={ useMultiColor }
                    />
                ) : ""
            }
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        identity: state.auth.identity,
        addons: state.addons.addonsList,
        attachment: state.shop.designAttachment
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        addToCart: (ownerCode, cartItem) => dispatch(addToCart(ownerCode, cartItem)),
        saveAttachment: ( file ) => dispatch(saveDesignAttachment(file)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductContent);